import React from "react";
import { Typography, Avatar, Grid, Box } from "@material-ui/core";
import Typed from "react-typed";
import { makeStyles } from "@material-ui/core/styles";
import avatar from "../images/headshot-circle.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(fab)

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(1),
  },
  title: {
    color: "white",
  },
  subtitle: {
    color: "#eeeeee",
  },
  typedContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100vw",
    textAlign: "center",
    zIndex: 1,
  },
  icon: {
    marginRight: "0.5em",
    color: "#98c"
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.typedContainer}>
      <Grid container justify="center">
        <Avatar className={classes.avatar} src={avatar} alt="Zoltán Tamási" />
      </Grid>
      <Typography className={classes.title} variant="h4">
        Zoltán Tamási
      </Typography>

      <Typography className={classes.subtitle} variant="h5">
        <Typed
          strings={[
            "Full-Stack Developer",
            "Software Engineer",
            "Functional programming enthusiast",
            "Music enthusiast"
          ]}
          typeSpeed={40}
          backSpeed={50}
          loop
        />
      </Typography>
      <Box component="section" sx={{ p: 3, border: '1px solid grey', backgroundColor: '#0003' }}>
        <Box sx={{ p:5 }}>
          <a href="https://www.linkedin.com/in/zoltan-tamasi/" aria-label="LinkedIn" target="_blank">
            <FontAwesomeIcon className={classes.icon} icon={["fab", "fa-linkedin"]} size="xl" />
          </a>
          <a href="https://github.com/zoltan-tamasi" aria-label="Github" target="_blank">
            <FontAwesomeIcon className={classes.icon} icon={["fab", "fa-github"]} size="xl" />
          </a>
          <a href="https://stackoverflow.com/users/2081056/zoltan-tamasi" aria-label="Stackoverflow" target="_blank">
            <FontAwesomeIcon className={classes.icon} icon={["fab", "fa-stack-overflow"]} size="xl" />
          </a>
          <a href="https://medium.com/@tzoltan84" aria-label="Medium" target="_blank">
            <FontAwesomeIcon className={classes.icon} icon={["fab", "fa-medium"]} size="xl" />
          </a>
          <a href="https://soundcloud.com/zoltan-tamasi" aria-label="Soundcloud" target="_blank">
            <FontAwesomeIcon className={classes.icon} icon={["fab", "fa-soundcloud"]} size="xl" />
          </a>
          <a href="mailto:mail@zoltantamasi.net" aria-label="Email" target="_blank">
            <FontAwesomeIcon className={classes.icon} icon={faEnvelope} size="xl" />
          </a>
        </Box>
        <Box sx={{ m:1 }}>
          <Typography className={classes.subtitle} sx={{ m:5 }}>
            <a href="https://zoltantamasi.art" aria-label="Music-portfolio" target="_blank" style={{ color: "#eee", textDecoration: "none" }}>My music portfolio page</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
