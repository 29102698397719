import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import trichords from "../images/trichords.webp";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233c",
    height: "100%",
  },
  cardContainer: {
    maxWidth: 345,
    margin: "3rem auto",
  },
}));

const projects = [
  {
    name: "Ear-training app",
    description: `This project has started out as a double-experiment. On one side to try out the Binding.scala library, 
      and find out how easy it is to develop browser based applications in Scala in general, especially compared to React/Redux. 
      On the other side this a music-theory experiment as well about trichords 
      and how easy it is to recognize different kinds of them.`,
    image: trichords,
    github: "https://github.com/zoltan-tamasi/eartraining-app",
    live: "http://trichords.s3-website-us-east-1.amazonaws.com"
  },
];

const Portfolio = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainContainer}>
      <Grid container justify="center">
        {/* Projects */}
        {projects.map((project, i) => (
          <Grid item xs={12} sm={8} md={4} key={i}>
            <Card className={classes.cardContainer}>
              <CardActionArea href={project.live} target="_blank">
                <CardMedia
                  component="img"
                  alt="Project 1"
                  height="140"
                  image={project.image}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {project.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button href={project.github} target="_blank" size="small" color="primary">
                  Github
                </Button>
                <Button href={project.live} target="_blank" size="small" color="primary">
                  Live Demo          
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Portfolio;
